<template>
  <overlay-scrollbars class="page page-balance">
    <div class="box box_myBalance">
      <div class="header">
        <div class="title">My balance</div>
        <div class="block">
          <Stat class="img" />
          <div class="block_content">
            <div class="subtxt">Balance status</div>
            <div class="txt">{{ number_format(myBalance.status, 2, '.', ' ') }}€</div>
          </div>
        </div>
        <div class="block">
          <Graph class="img" />
          <div class="block_content">
            <div class="subtxt">Total request cost</div>
            <div class="txt">{{ number_format(myBalance.trc, 2, '.', ' ') }}€</div>
          </div>
        </div>
        <div class="block">
          <CircleLoad class="img" />
          <div class="block_content">
            <div class="subtxt">Must be covered cost <ArrowFlat class="high" /></div>
            <div class="txt">{{ number_format(myBalance.mbcc, 2, '.', ' ') }}€</div>
          </div>
        </div>
        <div class="block block-paymentDeadline">
          Payment Deadline:
          <span class="val">{{ myBalance.paymentDeadline.days }}</span> <span class="desc">days</span>
          <span class="val">{{ myBalance.paymentDeadline.hours }}</span> <span class="desc">hours</span>
        </div>
      </div>
    </div>
    <div class="box box_appList">
      <div class="header">
        <div class="title">YOUR APPLICATION LIST</div>
      </div>
      <div class="content">
        <div class="block">
          <div class="hdr">
            <Bank2 class="img" /><div class="ttl">Bank account</div>
          </div>
          <Table :key="bankAccKey" @apply="apply" :options="bankAcc" />
        </div>
        <div class="block">
          <div class="hdr">
            <CreditCards class="img" /><div class="ttl">Merchant account</div>
          </div>
          <Table :key="merchantAccKey" @apply="apply" :options="merchantAcc" />
        </div>
        <div class="block">
          <div class="hdr">
            <Bank2 class="img" /><div class="ttl">Legal Service</div>
          </div>
          <Table :key="legalAccKey" @apply="cancelLegal" :options="legalService" />
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import Info from '@/components/elem/Info.vue'
import Table from '@/components/elem/Table.vue'

import Stat from '@/components/img/Stat.vue'
import Graph from '@/components/img/Graph.vue'
import CircleLoad from '@/components/img/CircleLoad.vue'
import ArrowFlat from '@/components/img/ArrowFlat.vue'
import Bank2 from '@/components/img/Bank2.vue'
import CreditCards from '@/components/img/CreditCards.vue'
import Close from '@/components/img/Close.vue'

export default {
  components: {
    Info,
    Table,
    Stat,
    Graph,
    CircleLoad,
    ArrowFlat,
    Bank2,
    CreditCards,
    Close,
  },
  data: () => ({
    myBalance: {
      status: '',
      trc: '',
      mbcc: '',
      paymentDeadline: {
        days: '',
        hours: ''
      },
    },
    bankAccKey: 0,
    merchantAccKey: 0,
    legalAccKey: 0,
    bankOffersData: [],
    merchantOffersData: [],
    legalData: [],
    bankTotal: 0,
    merchantTotal: 0,
    legalTotal: 0,
    bankAcc: {
      colsWidth: ['3%', '10%', '10%', '12%', '13%', '15%', '15%', '15%', '5%'],
      header: [
        { ttl: '#', info: null },
        { ttl: 'Name of Bank', info: null },
        { ttl: 'Country', info: null },
        { ttl: 'Type of Account', info: null },
        { ttl: 'Open/Integration fee', info: null },
        { ttl: 'Incoming fee', info: null },
        { ttl: 'Outgoing fee', info: null },
        { ttl: 'Onboarding time deadline', info: null },
        { ttl: '', info: null },
      ],
      body: [],
      footer: {
        type: 'Total Price',
        val: ''
      }
    },
    merchantAcc: {
      colsWidth: ['3%', '10%', '10%', '12%', '13%', '15%', '15%', '15%', '5%'],
      header: [
        { ttl: '#', info: null },
        { ttl: 'Name of Bank', info: null },
        { ttl: 'Country', info: null },
        { ttl: 'Type of Zone', info: null },
        { ttl: 'Open/Integration fee', info: null },
        { ttl: 'Incoming fee', info: null },
        { ttl: 'Outgoing fee', info: null },
        { ttl: 'Onboarding time deadline', info: null },
        { ttl: '', info: null },
      ],
      body: [],
      footer: {
        type: 'Total Price',
        val: ''
      }
    },
    legalService: {
      colsWidth: ['3%', '13%', '15%', '15%', '32%', '15%', '5%'],
      header: [
        { ttl: '#', info: null },
        { ttl: 'Name of Legal Co.', info: null },
        { ttl: 'Type of Services', info: null },
        { ttl: 'Price', info: null },
        { ttl: 'Description of Benefit', info: null },
        { ttl: 'Service time deadline', info: null },
        { ttl: '', info: null },
      ],
      body: [],
      footer: {
        type: 'Total Price',
        val: ''
      }
    }
  }),
  created () {
    this.getBalanceSettings()
    this.getAppliedBankOffers()
    this.getAppliedMerchantOffers()
    this.getAppliedLegalServices()
    if (this.$store.state.user.balance) {
      this.myBalance.status = this.$store.state.user.balance
    } else {
      this.myBalance.status = 0
    }
  },
  methods: {
    getBalanceSettings () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'balance-settings/' + this.$store.state.user.id)
        .then(function (response) {
          if (response.data.success) {
            that.myBalance.paymentDeadline.days = response.data.balance_settings.payment_deadline_days
            that.myBalance.paymentDeadline.hours = response.data.balance_settings.payment_deadline_hours
          }
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    getAppliedBankOffers () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'offers/applied/bank-accounts')
        .then(function (response) {
          that.bankAcc.body = []
          that.bankOffersData = response.data
          let totalPrice = 0
          that.bankOffersData.forEach(el => {
            const timeLeftDaysNumber = (el.time_left) ? el.time_left.days_number : ''
            const timeLeftHoursNumber = (el.time_left) ? el.time_left.hours_number : ''
            that.bankAcc.body.push([
              el.serial_number, el.financial_institution_name, el.country_code, el.account_type, that.number_format(el.open_integration_fee, 2, '.', ' ') + '€', el.incoming_fee, el.outgoing_fee, { type: 'deadline_info', days: timeLeftDaysNumber, hours: timeLeftHoursNumber}, { type: 'cancel', applyType: 'bank-offer', id: el.id }
            ])
            totalPrice = parseFloat(totalPrice) + parseFloat(el.open_integration_fee)
          })
          that.bankTotal = totalPrice
          that.calculateRequestCost()
          totalPrice = that.number_format(totalPrice, 2, '.', ' ') + '€'
          that.bankAcc.footer.val = totalPrice
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    getAppliedMerchantOffers () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'offers/applied/merchant-accounts')
        .then(function (response) {
          that.merchantAcc.body = []
          that.merchantOffersData = response.data
          let totalPrice = 0
          that.merchantOffersData.forEach(el => {
            const timeLeftDaysNumber = (el.time_left) ? el.time_left.days_number : ''
            const timeLeftHoursNumber = (el.time_left) ? el.time_left.hours_number : ''
            that.merchantAcc.body.push([
              el.serial_number, el.financial_institution_name, el.country_code, el.account_type, that.number_format(el.open_integration_fee, 2, '.', ' ') + '€', el.incoming_fee, el.outgoing_fee, { type: 'deadline_info', days: timeLeftDaysNumber, hours: timeLeftHoursNumber}, { type: 'cancel', applyType: 'merchant-offer', id: el.id }
            ])
            totalPrice = parseFloat(totalPrice) + parseFloat(el.open_integration_fee)
          })
          that.merchantTotal = totalPrice
          that.calculateRequestCost()
          totalPrice = that.number_format(totalPrice, 2, '.', ' ') + '€'
          that.merchantAcc.footer.val = totalPrice
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    getAppliedLegalServices () {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'legal-service/applied')
        .then(function (response) {
          that.legalService.body = []
          that.legalData = response.data
          let totalPrice = 0
          that.legalData.forEach(el => {
            that.legalService.body.push([
              el.serial_number, el.legal_name, el.services_type, that.number_format(el.price, 2, '.', ' ') + '€',
              el.benefit_description, { type: 'deadline', days: el.time_left.days_number, days_text: el.time_left.days_text, hours: el.time_left.hours_number, hours_text: el.time_left.hours_text, id: el.id }
            ])
            totalPrice = parseFloat(totalPrice) + parseFloat(el.price)
          })
          that.legalTotal = totalPrice
          that.calculateRequestCost()
          totalPrice = that.number_format(totalPrice, 2, '.', ' ') + '€'
          that.legalService.footer.val = totalPrice
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    apply (payload) {
      const that = this
      axios.post(process.env.VUE_APP_BACKEND_API_URL + 'offers/toogle-apply/' + payload.id)
        .then((response) => {
          const res = response.data
          if (payload.applyType == 'bank-offer') {
            if (!res.applied) {
              that.bankAcc.body = []
              that.bankOffersData = that.bankOffersData.filter(offer => offer.id !== payload.id)
              let totalPrice = 0
              that.bankOffersData.forEach(el => {
                const timeLeftDaysNumber = (el.time_left) ? el.time_left.days_number : ''
                const timeLeftHoursNumber = (el.time_left) ? el.time_left.hours_number : ''
                that.bankAcc.body.push([
                  el.serial_number, el.financial_institution_name, el.country_code, el.account_type, that.number_format(el.open_integration_fee, 2, '.', ' ') + '€', el.incoming_fee, el.outgoing_fee, { type: 'deadline_info', days: timeLeftDaysNumber, hours: timeLeftHoursNumber}, { type: 'cancel', applyType: 'bank-offer', id: el.id }
                ])
                totalPrice = parseFloat(totalPrice) + parseFloat(el.open_integration_fee)
              })
              that.bankTotal = totalPrice
              that.calculateRequestCost()
              totalPrice = that.number_format(totalPrice, 2, '.', ' ') + '€'
              that.bankAcc.footer.val = totalPrice
              that.bankAccKey++
            }
          } else if (payload.applyType == 'merchant-offer') {
            that.merchantAcc.body = []
            that.merchantOffersData = that.merchantOffersData.filter(offer => offer.id !== payload.id)
            let totalPrice = 0
            that.merchantOffersData.forEach(el => {
              const timeLeftDaysNumber = (el.time_left) ? el.time_left.days_number : ''
              const timeLeftHoursNumber = (el.time_left) ? el.time_left.hours_number : ''
              that.merchantAcc.body.push([
                el.serial_number, el.financial_institution_name, el.country_code, el.account_type, that.number_format(el.open_integration_fee, 2, '.', ' ') + '€', el.incoming_fee, el.outgoing_fee, { type: 'deadline_info', days: timeLeftDaysNumber, hours: timeLeftHoursNumber}, { type: 'cancel', applyType: 'merchant-offer', id: el.id }
              ])
              totalPrice = parseFloat(totalPrice) + parseFloat(el.open_integration_fee)
            })
            that.merchantTotal = totalPrice
            that.calculateRequestCost()
            totalPrice = that.number_format(totalPrice, 2, '.', ' ') + '€'
            that.merchantAcc.footer.val = totalPrice
            that.merchantAccKey++
          }

        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    cancelLegal(payload) {
      const that = this
      axios.post(process.env.VUE_APP_BACKEND_API_URL + 'legal-service/toogle-apply/' + payload.id)
        .then((response) => {
          const res = response.data
          if (!res.applied) {
            that.legalService.body = []
            that.legalData = that.legalData.filter(legal => legal.id !== payload.id)
            let totalPrice = 0
            that.legalData.forEach(el => {
              that.legalService.body.push([
                el.serial_number, el.legal_name, el.services_type, that.number_format(el.price, 2, '.', ' ') + '€',
                el.benefit_description, { type: 'deadline', days: el.time_left.days_number, days_text: el.time_left.days_text, hours: el.time_left.hours_number, hours_text: el.time_left.hours_text, id: el.id }
              ])
              totalPrice = parseFloat(totalPrice) + parseFloat(el.price)
            })
            that.legalTotal = totalPrice
            that.calculateRequestCost()
            that.legalService.footer.val = that.number_format(totalPrice, 2, '.', ' ') + '€'
            that.legalAccKey++
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    calculateRequestCost () {
      this.myBalance.trc = parseFloat(this.bankTotal) + parseFloat(this.merchantTotal) + parseFloat(this.legalTotal)  
      this.myBalance.mbcc = this.myBalance.trc - this.myBalance.status
    }
  }
}
</script>

<style lang="scss" scoped>
.page-balance {
  .box {
    &_myBalance {
      background: linear-gradient(113.24deg, #13B497 16.01%, #05737A 106.71%);

      .header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        background-image: url('/img/group_61.png');
        background-position: 102% -0.5rem;
        background-repeat: no-repeat;
        background-size: auto;
        border-radius: 14px;

        .block {
          display: flex;
          padding: .25rem 1rem .75rem;

          svg.img {
            width: 1.5rem;
            height: 1.5rem;
            margin-top: 10px;
          }

          &_content {
            padding: 0 .75rem;

            .subtxt {
              position: relative;
              font-size: 10px;

              ::v-deep svg {
                width: .75rem;
                height: .75rem;
                margin: 0 4px;

                &.high {
                  transform: rotate(-90deg);
                  path {
                    fill: #B03636;
                  }
                }
              }
            }

            .txt {
              display: flex;
              align-items: center;
              font-size: 1.25rem;
              line-height: 1.1;

              ::v-deep svg {
                width: .75rem;
                height: .75rem;
                margin: 6px;

                &.high {
                  transform: rotate(-90deg);
                  path {
                    fill: #B03636;
                  }
                }
              }
            }
          }

          &-paymentDeadline {
            padding: 0.25rem 1.5rem;
            margin: 0 .75rem 0 2rem;
            background: #272E35;
            border-radius: 6px;
            align-items: baseline;

            .val {
              margin: 0 .25rem 0 .5rem;
              font-size: 22px;
              color: #62CDAC;
            }

            .desc {
              margin: 0 .25rem 0 -0.125rem;
              font-size: 14px;
            }
          }
        }
      }
    }

    &_appList {
      .content {
        padding: 0 0 1rem;

        .block {
          margin-bottom: 1.5rem;

          .hdr {
            display: flex;
            align-items: center;
            margin-left: .75rem;
            margin-bottom: .5rem;

            svg.img {
              width: 1.75rem;
              height: 1.75rem;
              margin-right: .5rem;
            }

            .ttl {
              padding: .25rem .5rem;
            }
          }
        }
      }
    }
  }
}
</style>